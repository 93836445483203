import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Graph = () => (
  
  <section id="graph">
    <Container>
      <Row>
        <Col>
          <div
            className="seo-h1 text-center text-lowercase mt-0 mb-5"
          >
            <h1 className="m-0">
            Ok, wat nu?
            </h1>
          </div>
          <ol className="graph">
            <li className="graph-item">
              <span>1</span>
              <div>
              We lezen je bericht,<br/>
              bedankt!
              </div>
            </li>
            <li className="graph-item">
              <span>2</span>
              <div>
              Je krijgt binnen <br/>
              zes uur antwoord
              </div>
            </li>
            <li className="graph-item">
              <span>3</span>
              <div>
              We regelen voor jou een half uur durende <br/>
              vergadering met een van onze experts.<br/>
              </div>
            </li>
            <li className="graph-item">
              <span>4</span>
              <div>
              Je ontvangt een offerte
              binnen vijf werkdagen
              </div>
            </li>
            <li className="graph-item">
              <span>5</span>
              <div>
              Verliep alles naar wens?<br/>
              Aan de slag! 
              </div>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Graph
