import React from 'react'
import Link from '../common/Link'
import horizontalDiagram from '../../assets/images/process_diagram_hor.svg'
import verticalDiagram from '../../assets/images/process_diagram_vert.svg'

const ProcessDiagram = () => (
  <section id="process" className="bg-dark">
    <div className="container text-center">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0">
          <h1 className="text-lowercase">
          We laten ons <span className="text-primary">leiden door processen</span>
          </h1>
          <div className="lead">
            <h1 className="seo-strong">
              <Link to="/agile-lifecycle-management/" className="link-unstyled">
                Agile Project Lifecycle Management{' '}
              </Link>
            </h1>
            is de optimale manier voor procesbeheer bij het maken van software
            Door mee te doen met het proces kan je het gemakkelijker in de gaten houden en zijn doelmatigheid beoordelen.
          </div>
          <p style={{ marginTop: '2rem' }}>
            <Link
              to="/agile-lifecycle-management/"
              className="btn btn-outline-primary"
            >
              <span className="fas fa-info-circle" /> Leer meer over Agile PLM
            </Link>
          </p>

        </div>
      </div>
      <img
        src={horizontalDiagram}
        alt="SCRUM Process Diagram"
        className="process-diagram img-fluid d-none d-md-block"
      />
      <img
        src={verticalDiagram}
        alt="SCRUM Process Diagram"
        className="process-diagram img-fluid d-block d-md-none"
      />
    </div>
  </section>
)

export default ProcessDiagram
