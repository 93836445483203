import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faGlobe, faMale } from '@fortawesome/pro-light-svg-icons'
import Link from '../common/Link'

const SelectedSingleProject = ({ project }) => {
  return (
    <section id="selected-single-project" className="project-presentation">
      <div className="container">
        <p className="seo-h1 text-lowercase text-center">geselecteerd project</p>
        <div className="row py-5 flex-column-reverse flex-md-row">
          <div className="col-md-6">
            <div className="image-container">
              <img
                src={project.image.childImageSharp.fluid.src}
                style={{ maxWidth: '100%', maxHeight: '500px' }}
                alt="project-img"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center text-md-left">
              <p className="seo-h2" style={{ fontSize: '2.6rem', marginBottom: '30px' }}>
                {project.title}
              </p>
              <p className="text-muted">Project voor {project.client}</p>
              <p>{project.description}</p>
              <div className="row project-data">
                <div
                  className="col-sm-4 col-md-6 col-lg-4 project-data-item mb-3 mb-sm-0"
                >
                  <FontAwesomeIcon icon={faGlobe} className="project-data-icon" />
                  <span className="text-muted">{project.country}</span>
                </div>
                <div
                  className="col-sm-4 col-md-6 col-lg-4 project-data-item mb-3 mb-sm-0"
                >
                  <FontAwesomeIcon icon={faMale} className="project-data-icon" />
                  <span className="text-muted">{project.team}</span>
                </div>
                <div
                  className="col-sm-4 col-md-6 col-lg-4 mt-md-4 mt-lg-0 project-data-item mb-3 mb-sm-0"
                >
                  <FontAwesomeIcon icon={faClock} className="project-data-icon" />
                  <span className="text-muted">{project.time}</span>
                </div>
              </div>
              <Link to={`/projects/${project.slug}/`}>
                <button className="btn btn-outline-primary mt-4 mt-sm-5 mb-5 mb-sm-4">
                  Zie casestudy
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelectedSingleProject
